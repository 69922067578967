import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { AuthenticationService } from 'app/services/authentication.service';
// import * as moment from 'moment';
import moment from 'moment-timezone';
import { CurrencyPipe } from '@angular/common';
import { TimeLineService } from 'app/services/time-line.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()

export class GlobalFuntions {

    user: any;
    agency: any;

    constructor(
        private _authenticationService: AuthenticationService,
        private _timeLineService: TimeLineService,
        private _currencyPipe: CurrencyPipe,
        private _translateService: TranslateService
    ) {
        this._authenticationService.currentUser
            .subscribe(() => this.user = this._authenticationService.currentUserValue);
        this._authenticationService.currentAgency
            .subscribe(() => this.agency = this._authenticationService.currentAgencyValue);
    }

    caseAndAccentInsensitive = (() => {
        const accentMap = ((letters) => {
            const map = {};
            while (letters.length > 0) {
                const letter = '[' + letters.shift() + ']';
                const chars = letter.split('');
                while (chars.length > 0) {
                    map[chars.shift()] = letter;
                }
            }
            return map;
        })([
            'aàáâãäå', // a
            'cç', // c
            'eèéêë', // e
            'iìíîï', // i
            'nñ', // n
            'oòóôõöø', // o
            'sß', // s
            'uùúûü', // u
            'yÿ' // y
        ]);
        return (text: string) => {
            let textFold = '';

            if (!text) {
                return textFold;
            }

            text = text.toLowerCase();
            for (let idx = 0; idx < text.length; idx++) {
                const charAt = text.charAt(idx);

                textFold += accentMap[charAt] || charAt;
            }
            return '(?i)' + textFold;
        };
    })();

    // this method will remove all the null or empty values
    cleanObject(obj: any): any {
        Object
            .entries(obj)
            .forEach(([k, v]: any) => {
                if (v && typeof v === 'object') {
                    // Check if v is a Date object and skip cleaning
                    if (v instanceof Date) {
                        return; // Skip cleaning this Date object
                    }
                    this.cleanObject(v);
                }
                if (v && typeof v === 'object' && !Object.keys(v).length || v === null || v === undefined || v==='') {
                    if (Array.isArray(obj)) {
                        obj.splice(k, 1);
                    } else {
                        delete obj[k];
                    }
                }
            });
        return obj;
    }

    // Remove the null or empty values only for new entries if entry already exist in the db then it will not remove
    cleanObjectForNewEntries(obj: any, oldObj: any, newEmptyValues:Array<any> = []): any {
        Object.entries(obj)
            .forEach(([k, v]: any) => {
                const excludedKeys = ["categories", "climate_control", "conditions", "distances", "features", "furniture", "garden", "kitchen", "leisures", "living_room", "null", "orientations", "parking", "pool", "rooms", "security", "settings", "utility", "views"];
                if (v && typeof v === 'object') {
                    let oldV = [];
                    if (oldObj[k]) {
                        oldV = oldObj[k];
                    }
                    this.cleanObjectForNewEntries(v, oldV, newEmptyValues);
                }
                if (v && typeof v === 'object' && !Object.keys(v).length || v === null || v === undefined || v === '') {
                    if (
                        !(k in oldObj) ||
                        [0, null, undefined, ''].includes(oldObj[k]) ||
                        (Array.isArray(oldObj[k]) && !oldObj[k].length) ||
                        excludedKeys.indexOf(k) !== -1 // For `features`
                    ) {
                        if (Array.isArray(obj)) {
                            obj.splice(k, 1);
                        } else {
                            delete obj[k];
                        }
                    } else if (k !== 'length') {
                        newEmptyValues.push(k);
                    }
                }
            });
        return obj;
    }

    clearObject(obj: object): object {
        if (_.isArray(obj)) {
            _.remove(obj, (o) => o === '' || o === null);
            return _(obj)
                .filter(_.isObject)
                .map((o) => this.clearObject(o))
                .reject(_.isEmpty)
                .concat(_.reject(obj, _.isObject))
                .value();
        }
        obj = _.pickBy(obj, _.identity);
        return _(obj)
            .pickBy(_.isObject)
            .mapValues((o) => this.clearObject(o))
            .omitBy(_.isEmpty)
            .assign(_.omitBy(obj, _.isObject))
            .value();
    }

    /**
     * Deep diff between two object, using lodash
     * @param  {Object} object Object compared
     * @param  {Object} base   Object to compare with
     * @return {Object}        Return a new object who represent the diff
     */
    difference(object: any, base: any): any {
        return _.transform(object, (result, value, key: any) => {
            if (!_.isEqual(value, base[key]) && key !== '_id' && key !== 'created_at' && key !== 'updated_at') {
                result[key] = (_.isObject(value) && _.isObject(base[key])) ? this.difference(value, base[key]) : value;
            }
        });
    }

    disableFunction(agency: any, createdBy: any): any {
        if (this.user.user_role === 'admin' || this.user.user_role === 'agency_admin') {
            if (this.user.user_agency === agency) {
                return true;
            }
            else {
                return false;
            }
        } else if (this.user.user_role === 'agency_manager' && this.user.user_id === createdBy) {
            return true;
        } else {
            if (this.user.user_id === createdBy) {
                return true;
            }
            else {
                return false;
            }
        }
    }

    disableFuncContacts(agency: any, createdBy: any): any {
        if (this.user.user_role === 'admin') {
            return true;
        }
        else {
            if (this.user.user_role === 'agency_manager' || this.user.user_role === 'agency_admin') {
                if (this.user.user_agency === agency) {
                    return true;
                }
                else {
                    return false;
                }
            }
            else {
                if (this.user.user_id === createdBy) {
                    return true;
                }
                else {
                    return false;
                }
            }
        }
    }

    disableFuncSales(agencyUsers: any, createdBy: any): any {
        if (this.user.user_role === 'agency_manager' || this.user.user_role === 'agent') {
            if (_.includes(agencyUsers, createdBy)) {
                return true;
            }
            else {
                return false;
            }
        } else {
            return true;
        }
    }

    activityDisableFuntion(createdBy: any, agency: any, assignee: any): any {
        if (this.user.user_role === 'admin' || this.user.user_role === 'agency_admin' || this.user.user_role === 'agency_manager') {
            if (this.user.user_agency === agency) { return true; }
            else { return false; }
        } else {
            if (this.user.user_id === createdBy || this.user.user_id === assignee) { return true; }
            else { return false; }
        }
    }

    addEditDisableFunc(agency: any): boolean {
        if (this.user.user_role === 'admin') {
            return true;
        } else if (agency === this.agency._id) {
            return true;
        } else {
            return false;
        }

    }

    dateFromChanges(dateFrom: any, dateTo: any): void {
        const dateUntil = moment(dateFrom.value).add(7, 'days');
        if (moment(dateTo.value) < moment(dateFrom.value)) {
            dateTo.setValue(dateUntil);
        }
        if (!dateTo.value) {
            dateTo.setValue(dateUntil);
        }
    }

    dateToChanges(dateFrom: any, dateTo: any): void {
        if (moment(dateTo.value) < moment(dateFrom.value)) {
            dateTo.setValue(moment(dateFrom.value));
        }
    }

    datePatch(formControl: any, dateFrom: any): void {
        formControl.setValue(moment(dateFrom * 1000).format());            
    }

    onCallMobile(data: any, callNumber?: any): void {
        const message = {
            message: 'Outgoing call',
            activity_type: data.activity_type,
            related_to: 'Property',
            related_to_id: '',
            comment: 'Outgoing call',
            created_by: this.user.user_id,
            model: data.model,
            model_id: data._id,
            user: this.user.full_name,
            user_id: this.user.user_id,
        };

        this._timeLineService.addComment(this.cleanObject(message))
            .subscribe(() => {
                window.location.href = 'tel:' + callNumber;
            });
    }

    accessAccountData(account: any): boolean {
        const assignedTo = _.isArray(account.assigned_to) ? account.assigned_to : [];
        const isAgent = this.user.user_role === 'agent' || this.user.user_role === 'agent_medium' || this.user.user_role === 'admin_agent_light';
        //const hasAccess = this.agency.agent_access;
        const accesToAccount = account.created_by === this.user.user_id || _.indexOf(assignedTo, this.user.user_name) > -1;
        if (isAgent) {
            return accesToAccount;
        } else {
            return true;
        }
    }
    accessOwnerData(owner: any): boolean {
        const assignedTo = _.isArray(owner.assign_agent) ? owner.assign_agent : [];
        const isAgent = this.user.user_role === 'agent' || this.user.user_role === 'agent_medium' || this.user.user_role === 'admin_agent_light';
        // const hasAccess = !this.agency.agent_access_owner;
        const accesToOwner = owner.created_by === this.user.user_id || _.indexOf(assignedTo, this.user.user_id) > -1;
        if (isAgent) {
            return accesToOwner;
        } else {
            return true;
        }
    }

    setPaginator(user): any {
        const pagenator = {} as any;
        pagenator.pageSizeOptions = this.paginatorSizes();
        pagenator.pageSize = 30;
        return pagenator;
    }

    paginatorSizes(): any {
        return [30, 35, 40, 45, 50];
    }

    getUserRole(role: any): boolean {
        if (role === 'admin_agent_light' || role === 'agent') {
            return true;
        } else {
            return false;
        }
    }
    sortRegardingLanguage(dataset, fieldName, language, direction?): any {

        direction = direction ? direction : 'asc';
        return _.orderBy(dataset, (o) => { return o[fieldName][language] }, direction);

    }

    getDays(day: any): any {
        switch (day) {
            case 1: {
                return this._translateService.instant('Sun');
                break;
            }
            case 2: {
                return this._translateService.instant('Mon');
                break;
            }
            case 3: {
                return this._translateService.instant('Tue');
                break;
            }
            case 4: {
                return this._translateService.instant('Wed');
                break;
            }
            case 5: {
                return this._translateService.instant('Thu');
                break;
            }
            case 6: {
                return this._translateService.instant('Fri');
                break;
            }
            case 0: {
                return this._translateService.instant('Sat');
                break;
            }
            default: {
                return null;
                break;
            }
        }
    }

    getMonths(month: any): any {
        switch (month) {
            case 1: {
                return this._translateService.instant('JAN');
                break;
            }
            case 2: {
                return this._translateService.instant('FEB');
                break;
            }
            case 3: {
                return this._translateService.instant('MAR');
                break;
            }
            case 4: {
                return this._translateService.instant('APR');
                break;
            }
            case 5: {
                return this._translateService.instant('MAY');
                break;
            }
            case 6: {
                return this._translateService.instant('JUN');
                break;
            }
            case 7: {
                return this._translateService.instant('JUL');
                break;
            }
            case 8: {
                return this._translateService.instant('AUG');
                break;
            }
            case 9: {
                return this._translateService.instant('SEP');
                break;
            }
            case 10: {
                return this._translateService.instant('OCT');
                break;
            }
            case 11: {
                return this._translateService.instant('NOV');
                break;
            }
            case 12: {
                return this._translateService.instant('DEC');
                break;
            }
            default: {
                return null;
                break;
            }
        }
    }
    agentAccess(): boolean {
        const isAgent = this.user?.user_role === 'agent' || this.user?.user_role === 'agent_medium' || this.user?.user_role === 'admin_agent_light';
        if (isAgent) {
            return false;
        } else {
            return true;
        }
    }
}
